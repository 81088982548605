import { IDType, IDTypeManager } from 'visyn_core/idtype';
import { PluginRegistry, IPluginDesc } from 'visyn_core/plugin';
import { IServerColumn, IRow } from 'visyn_core/base';
import { IARankingViewOptions, ARankingView, IViewContext, ISelection, DataCache, IServerColumnDesc, IAdditionalColumnDesc, ColumnDescUtils } from 'tdp_core';
import { LineUpDataSet, UploadDefaults } from './LineUpData';
import { EP_TDP_UPLOADED_DATA_LINEUP_COLUMN_DESC, EP_TDP_UPLOADED_DATA_LINEUP_ROWS } from '../common/extensions';

export interface IStoredLineUpOptions extends IARankingViewOptions {
  dataId: string;
  dataIDType: string;

  /**
   * Show additional columns (and data) for the given idType provided by plugins (i.e., using extension points)
   * @default false
   */
  showPluginColumns: boolean;
}

interface IUploadedData {
  idType: string;
  ids: number[];
  data: any[];
  columns: any[];
  primaryKey: string;
}

export class LineUpStoredData extends ARankingView {
  private readonly dataId: string;

  private dataIDType: string;

  private loader: Promise<IUploadedData> = null;

  constructor(context: IViewContext, selection: ISelection, parent: HTMLElement, options: Partial<IStoredLineUpOptions> = {}) {
    super(
      context,
      selection,
      parent,
      Object.assign(options, {
        enableAddingColumnGrouping: true,
        showPluginColumns: false,
        panelAddColumnBtnOptions: {
          btnClass: 'btn-primary',
        },
        // Extracts ranking options from .env file (via process.env) and returns them in an object that can be spread into the ranking options.
        ...(process.env.RANKING_ENABLE_VIS_PANEL == null ? {} : { enableVisPanel: JSON.parse(process.env.RANKING_ENABLE_VIS_PANEL) }),
      }),
    );
    this.dataId = options.dataId;
    this.dataIDType = options.dataIDType;
  }

  get itemIDType(): IDType {
    return IDTypeManager.getInstance().resolveIdType(this.dataIDType);
  }

  /**
   * Load the uploaded dataset by a given `dataId` property
   *
   * @returns {Promise<IUploadedData>} Returns the uploaded dataset
   */
  private async load(): Promise<IUploadedData> {
    if (this.loader === null) {
      const dataPromise: LineUpDataSet = (await DataCache.getInstance().get(this.dataId)) as LineUpDataSet;
      this.dataIDType = dataPromise.desc.idType || this.dataIDType || UploadDefaults.UPLOAD_DEFAULT_IDTYPE_NAME;

      this.loader = dataPromise.data();

      const loadedData: IUploadedData = await this.loader;

      if (typeof loadedData.primaryKey === 'number') {
        loadedData.columns.forEach((col) => {
          col.column = String(col.column);
        });
      }
    }
    return this.loader;
  }

  /**
   * Load column descriptions for the uploaded dataset and merge them with additional
   * column descriptions provided by registered extensions for the given idType.
   *
   * @returns {Promise<IServerColumnDesc>} Returns a view description containing the list of columns
   */
  protected async loadColumnDesc(): Promise<IServerColumnDesc> {
    // wait for data to load, because it will set the correct `this.dataIDType`
    const data: IUploadedData = await this.load();
    const pluginColumns = await this.loadPluginColumnDesc(this.dataIDType);

    // hide plugin columns in initial ranking, but still available from the add column dialog
    pluginColumns.forEach((column) => {
      column.initialRanking = (<IStoredLineUpOptions>this.options).showPluginColumns;
    });

    // merge column descriptions
    const columns = [...data.columns, ...pluginColumns];
    return { columns, idType: this.selection.idtype };
  }

  /**
   * Load plugins using phovea extension point and a given idType.
   * Each extension point must return a list of `IAdditionalColumnDesc[]`.
   *
   * @param idType {String} Look for extension points that matches given idType
   * @returns {IAdditionalColumnDesc[]} Returns a merged list of column descriptions
   */
  private async loadPluginColumnDesc(idType: string): Promise<IAdditionalColumnDesc[]> {
    const pluginPromises = PluginRegistry.getInstance()
      .listPlugins((desc) => desc.type === EP_TDP_UPLOADED_DATA_LINEUP_COLUMN_DESC && desc.idType === idType)
      .map((desc: IPluginDesc) => {
        return desc.load().then((plugin) => plugin.factory(idType));
      });
    const pluginColumns: IAdditionalColumnDesc[][] = await Promise.all(pluginPromises);
    return [].concat(...pluginColumns); // flatten the array
  }

  /**
   * Convert the column descriptions from the server to a LineUp column description
   * @param server {IServerColumn[]}
   */
  protected getColumnDescs(server: IServerColumn[]): IAdditionalColumnDesc[] {
    // server already in the right format but wrap with common wrapper
    return server.map((d) => Object.assign(ColumnDescUtils.stringCol(''), d));
  }

  /**
   * Load rows for the uploaded dataset and merge them with additional rows
   * provided by registered extensions for the given idType.
   *
   * @returns {Promise<IRow[]>} Returns a promise with a list of rows
   */
  protected async loadRows(): Promise<IRow[]> {
    // wait for data to load, because it will set the correct `this.dataIDType`
    const data: IUploadedData = await this.load();

    // create an artificial row id using the index if no id column is set
    if (data.primaryKey === UploadDefaults.UPLOAD_DEFAULT_ID_COLUMN) {
      data.data = data.data.map((row: any[], index: number) => {
        const r: any = {
          id: index,
        };
        row.forEach((v, i) => (r[String(i)] = v));
        return r;
      });
    } else if (typeof data.primaryKey === 'number') {
      // going to be a data array, process it
      const primary: number = data.primaryKey;
      data.data = data.data.map((row: any[]) => {
        const r: any = {
          id: row[primary],
        };
        row.forEach((v, i) => (r[String(i)] = v));
        return r;
      });
    }

    if (data.ids) {
      // we have the ids, merge them into the data
      data.data.forEach((d: any, i) => (d._id = data.ids[i]));
    }

    // look for registered plugins that provide more rows for the given idType and list of ids
    const ids = this.mapUploadedDataToIds(data);
    const pluginRows = await this.loadPluginRows(this.dataIDType, ids);

    // HEADS UP! equal properties from uploaded data are overriden by the plugin rows
    return this.mergeRows(data.data, pluginRows);
  }

  /**
   * Convert loaded data into a list of ids based on the provided idType column
   * (e.g., list of ensembl ids).
   *
   * @param data {IUploadedData} Uploaded dataset
   * @returns {string[]} List of ids from the idType column
   */
  private mapUploadedDataToIds(data: IUploadedData): string[] {
    const index = data.columns.findIndex((column) => column.idType !== undefined);
    if (index === -1) {
      return [];
    }
    return data.data.map((row) => row[index]);
  }

  /**
   * Load plugins using phovea extension point and a given idType.
   * Each extension point must return a list of `IRow[]`.
   *
   * @param idType {string} Look for extension points that matches given idType
   * @param ids {string[]} List of ids used to reduce the amount of loaded rows
   * @returns {IRow[]} Returns a merged list of rows
   */
  private async loadPluginRows(idType: string, ids: string[]): Promise<IRow[]> {
    const pluginPromises = PluginRegistry.getInstance()
      .listPlugins((desc) => desc.type === EP_TDP_UPLOADED_DATA_LINEUP_ROWS && desc.idType === idType)
      .map((desc: IPluginDesc) => {
        return desc.load().then((plugin) => plugin.factory(idType, ids)); // pass idType and list of ids
      });

    if (pluginPromises.length === 0) {
      return Promise.resolve([]); // no plugin founds = empty list of rows
    }

    const pluginRows: IRow[][] = await Promise.all(pluginPromises);
    return this.mergeRows(...pluginRows);
  }

  /**
   * Merges multiple row lists provided as function parameters.
   * The function merges object by id of the rows.
   * Properties that exists in multiple rows are overriden by the latest object.
   *
   * @param rowList {IRow[]} List of rows as parameter
   * @returns {IRow[]} List of merged rows ordered by the first row list parameter
   */
  private mergeRows(...rowList: IRow[][]): IRow[] {
    if (rowList.length === 0) {
      throw new Error('Expects at least two row lists for merging. None was given.');
    } else if (rowList.length === 1) {
      // single list = nothing to merge
      return rowList[0];
    }

    const rowsMap = new Map<string, IRow>();
    rowList.forEach((rows: IRow[]) => {
      rows.forEach((row: IRow) => {
        const existingRow = rowsMap.has(row.id) ? rowsMap.get(row.id) : {};
        rowsMap.set(row.id, Object.assign(existingRow, row));
      });
    });

    // the order of the merged rows matches the first row list parameter
    // reason: Map remembers original insertion order of the keys
    // see also https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Map
    return Array.from(rowsMap.values());
  }
}
