import { AppContext, Ajax } from 'visyn_core/base';
import { IDataDescription, ADataType, IDataType } from 'tdp_core';

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace UploadDefaults {
  /**
   * Name of the default idType if no idType can be found or the user did not select an idType when uploading a file.
   */
  export const UPLOAD_DEFAULT_IDTYPE_NAME = 'Custom';

  /**
   * The id column property for the default idType (see `UPLOAD_DEFAULT_IDTYPE_NAME`).
   * If no id column is set when uploading a file, the row id is artificially generated
   * using the row index (i.e., line number of the file).
   */
  export const UPLOAD_DEFAULT_ID_COLUMN = '_index';
}

export interface ILineUpDataSetDescription extends IDataDescription {
  size: number;
}
export interface ILineUpLayoutDataDescription extends IDataDescription {
  dataset: { id: string };
}

export class LineUpDataSet extends ADataType<ILineUpDataSetDescription> {
  private _cache = undefined;

  get dim() {
    return [this.length];
  }

  size() {
    return this.desc.size;
  }

  get length() {
    return this.size();
  }

  data() {
    if (this._cache) {
      // in the cache
      return this._cache;
    }
    return (this._cache = AppContext.getInstance().getAPIJSON(`/dataset/${this.desc.id}`));
  }
}

export class LineUpLayout extends ADataType<ILineUpLayoutDataDescription> {
  private _cache = undefined;

  data() {
    return Ajax.getData(this.desc.dataset.id);
  }

  layout() {
    if (this._cache) {
      // in the cache
      return this._cache;
    }
    return (this._cache = AppContext.getInstance().getAPIJSON(`/dataset/${this.desc.id}`));
  }

  static create(desc: ILineUpDataSetDescription | ILineUpLayoutDataDescription): IDataType {
    if (desc.type === 'lineup_data') {
      return new LineUpDataSet(<ILineUpDataSetDescription>desc);
    }
    return new LineUpLayout(<ILineUpLayoutDataDescription>desc);
  }
}
