import { IRegistry, ILocaleEPDesc, PluginRegistry, EP_PHOVEA_CORE_LOCALE } from 'visyn_core/plugin';
import { EXTENSION_POINT_TDP_RANKING_BUTTON } from 'tdp_core';
import { EP_ORDINO_STARTMENU_DATASET_SECTION, IStartMenuDatasetSectionDesc } from 'ordino';
import { UploadDefaults } from './lineup';

// register all extensions in the registry following the given pattern
export default function (registry: IRegistry) {
  // registry.push('extension-type', 'extension-id', function() { return System.import('./extension_impl'); }, {});
  registry.push(
    'datatype',
    'lineup_data',
    function () {
      return import('./lineup/LineUpData');
    },
    {
      factory: 'new LineUpDataSet',
    },
  );

  registry.push(
    'datatype',
    'lineup_layout',
    function () {
      return import('./lineup/LineUpData').then((l) => l.LineUpLayout);
    },
    {
      factory: 'create',
    },
  );

  registry.push(
    'tdpView',
    'tdp_start_loaded',
    function () {
      return import('./lineup/LineUpStoredData');
    },
    {
      factory: 'new LineUpStoredData',
      name: 'Stored LineUp',
      idtype: UploadDefaults.UPLOAD_DEFAULT_IDTYPE_NAME,
      selection: 'none',
    },
  );

  /// #if include('ordino')
  registry.push(EP_ORDINO_STARTMENU_DATASET_SECTION, 'tdp_uploaded_dataset', () => import('./components/UploadDatasetCard'), <IStartMenuDatasetSectionDesc>{
    name: 'Upload',
    icon: 'fas fa-file-upload',
    startViewId: 'tdp_start_loaded',
    idType: UploadDefaults.UPLOAD_DEFAULT_IDTYPE_NAME,
    // priority: 60
  });
  /// #endif

  registry.push(
    EXTENSION_POINT_TDP_RANKING_BUTTON,
    'tdp_load_score',
    function () {
      return import('./upload/UploadedScore').then((u) => u.UploadedScoreUtils);
    },
    {
      cssClass: 'upload-score-button',
      faIcon: 'fas fa-upload',
      factory: 'createScore',
      title: 'Upload Data Column(s)',
    },
  );

  registry.push(
    'tdpScoreImpl',
    'tdp_load_score',
    function () {
      return import('./upload/UploadedScore');
    },
    {
      factory: 'new UploadedScore',
    },
  );

  registry.push(
    EP_PHOVEA_CORE_LOCALE,
    'tdpUploadedDataLocaleEN',
    function () {
      return import('./locales/en/tdp.json').then(PluginRegistry.getInstance().asResource);
    },
    <ILocaleEPDesc>{
      ns: 'tdp',
    },
  );
}
